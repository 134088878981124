<template>
  <svg
    id="Component_444_1"
    data-name="Component 444 – 1"
    xmlns="http://www.w3.org/2000/svg"
    width="25.002"
    height="24.968"
    viewBox="0 0 25.002 24.968"
  >
    <path
      id="Path_3910"
      data-name="Path 3910"
      d="M371.578,549.995c-.394-.734-.773-1.419-1.132-2.115-.418-.812-.818-1.634-1.223-2.453-.2-.409-.42-.813-.593-1.234a7.714,7.714,0,0,1-.272-1.361,7.178,7.178,0,0,0-1.474-3.086,7.656,7.656,0,0,0-3.364-2.561c-.307-.106-.594-.285-.906-.359a8.83,8.83,0,0,0-1.39-.213c-.491-.035-.986,0-1.479-.008a7.33,7.33,0,0,0-5.047,2.106,7.555,7.555,0,0,0-2.087,2.941,5.266,5.266,0,0,0-.571,2.41,14.157,14.157,0,0,1,.008,1.479,6.769,6.769,0,0,0,1.18,3.735,8.237,8.237,0,0,0,3.891,3.286c.357.144.721.269,1.12.418a7.769,7.769,0,0,0-.867,1.811,8.5,8.5,0,0,0,.107,2.068c-.462.231-.935-.083-1.365-.252a15.183,15.183,0,0,1-3.677-2.118,11.906,11.906,0,0,1-3.385-4.308,7.675,7.675,0,0,1-1.02-3.592,4.117,4.117,0,0,1-.191-2.342,11.588,11.588,0,0,1,2.053-6.223,15.881,15.881,0,0,1,1.786-2.223,14.086,14.086,0,0,1,5.115-2.957c.544-.127,1.144-.186,1.686-.3a8.524,8.524,0,0,1,2.352-.149,11.707,11.707,0,0,1,6.239,2.016,11.006,11.006,0,0,1,2.069,1.641,13.213,13.213,0,0,1,2.649,4.081,20.014,20.014,0,0,1,.813,2.948,8.961,8.961,0,0,1,.15,2.245A10.523,10.523,0,0,1,371.578,549.995Z"
      transform="translate(-347.765 -532.37)"
      fill="#ffc201"
    />
    <path
      id="Path_3911"
      data-name="Path 3911"
      d="M379.791,568.507v-.9l3.383-1.925c0-1.388.032-2.859-.017-4.327a1.333,1.333,0,0,1,.631-1.282l.654.549c0,1.755,0,3.477,0,5.2a.86.86,0,0,1-.474.884c-1.033.643-2.157,1.14-3.125,1.88C380.464,568.869,380.185,568.649,379.791,568.507Z"
      transform="translate(-371.278 -552.707)"
      fill="#ffc201"
    />
    <path
      id="Path_3912"
      data-name="Path 3912"
      d="M402.009,584.266a5.729,5.729,0,0,1-.432-.874c-1.465-2.913-2.953-5.815-4.378-8.748a1.74,1.74,0,0,0-1.178-.969,2.437,2.437,0,0,0-.945-.008,1.425,1.425,0,0,0-1.138.653,18.945,18.945,0,0,0-1.118,2.079c-1.406,2.8-2.791,5.6-4.184,8.4a1.973,1.973,0,0,0,1.751,2.812c1.69-.005,3.379,0,5.069,0,1.619,0,3.239-.021,4.857.011a2.3,2.3,0,0,0,1.412-.43,2.108,2.108,0,0,0,.7-1.092A2.63,2.63,0,0,0,402.009,584.266Zm-7.193-5.94c.045-.344.52-.6.772-.385a1,1,0,0,1,.506.842c-.039,1.156-.009,2.315-.016,3.472,0,.417-.088.569-.652,1.262l-.614-.864v-3.8A4.071,4.071,0,0,1,394.815,578.326Zm.646,7.084a5.228,5.228,0,0,1-.574-.586.467.467,0,0,1,.065-.64.494.494,0,0,1,.641-.109,4.683,4.683,0,0,1,.62.472Z"
      transform="translate(-377.63 -562.659)"
      fill="#ffc201"
    />
  </svg>
</template>

<script>
// TODO: This icon is not used anywhere
export default {
  props: {
    height: {
      type: String,
      default: '16.199',
    },
    width: {
      type: String,
      default: '16.199',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  computed: {
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
